import React from "react";

import  pdfMake  from 'pdfmake/build/pdfmake';
import  pdfFonts  from "pdfmake/build/vfs_fonts";
import { styled } from 'styled-components';

function CardPointPDF(pointerColab){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Relatorio de Folha de Ponto',
            fontSize: 25,
            bold: true,
            margin: [50,20, 10, 100], // left, top, rigth, bottom
            alignment:'center'
        }
    ];

    const dados = pointerColab.map((pointer) => {
        return [
            
            {text: pointer.dia, style: 'tableHeader', fontSize: 9},
            {text: pointer.entrada_1_period, style: 'tableHeader', fontSize: 9},
            {text: pointer.saida_1_period, style: 'tableHeader', fontSize: 9},
            {text: pointer.entrada_2_period, style: 'tableHeader', fontSize: 9},
            {text: pointer.saida_2_priod, style: 'tableHeader', fontSize: 9}

        ]
    })

    const details = [
        {
            table:{
                headerRows: 1,
                widths:['*','*','*','*','*','*', ],
                body:[
                    [
                        
                        {text: 'Data', style: 'tableHeader', fontSize: 10, bold: true},
                        {text: 'Entrada 1', style: 'tableHeader', fontSize: 10, bold: true},
                        {text: 'Saida 1', style: 'tableHeader', fontSize: 10, bold: true},
                        {text: 'Entrada 2', style: 'tableHeader', fontSize: 10, bold: true},
                        {text: 'Saida 2', style: 'tableHeader', fontSize: 10, bold: true}
                    ],
                    ...dados
                ]

            },
           
            
            layout:'lightHorizontalLines',

            
        },
       
    ];

    function rodaPe(currentPage, PageCount){
        return[
            {
                text: " Rhally Sistema de Gestão e Recursos Humanos - Folha de Ponto - página " + currentPage + ' / ' + PageCount,
                alignment: 'rigth',
                fontSize: 9,
                margin:[25, 10, 20, 10],
                marginBottom: 100

            }
        ]
    }
    
    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15,50,15,40],

        header: [reportTitle],
        content: [details],
        footer: rodaPe
    }
    pdfMake.createPdf(docDefinitions).print();
    
}
export default CardPointPDF