import {React, useState} from "react";
//import { Form.Label, Form.Control, Salved } from "../components/style";
import {Link, Route, Routes} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import {Form, Button} from 'react-bootstrap';
//import LoaderPage from "../components/loading/LoaderPage";
import Axios from "axios";
import Loader from './../components/loading/Loader';
import { useNavigate } from "react-router-dom";
import MenuSistem from './../components/MenuSistem';


function LoginEmployer(){
    const navigate = useNavigate()
    

    const [Email, setEmail] = useState('');
    const [Senha, setSenha] = useState('');
    const [user, setUser] = useState('');
    const [error, setError] = useState('');
    const typeUser = 'employer';
    const [removeloading, setRemoveLoading] = useState(false)
    
   /* const dados  ={
        'dadoEmail': email,
        'dadoSenha': senha
    }
*/
   /* function handleClick(){
        if(email || senha){
            Axios.post('http://localhost:4000/api/loginEmployer',{
                Email: dados.dadoEmail,
                Senha: dados.dadoSenha
            }).then((response) =>{                
                console.log(response.data.msg)
            })
        }
        else{
            alert("É nescessário preencher todos os campos!")
        }
     
       
          

    } */

    const handleClick = async (e) =>{
        setRemoveLoading(true)
        e.preventDefault();
        
        try {
            const response = await Axios.post('http://vps50439.publiccloud.com.br:3000/api/loginEmployer',
            JSON.stringify({Email, Senha}),
            {
                headers: {'Content-Type': 'application/json'}
            }
            );
            localStorage.setItem('token', response.data.token); // token de acesso de serviço
            localStorage.setItem('userId', response.data.id); // id de usuário*
            localStorage.setItem('enterpriseId', response.data.idEnterprise); // id da empresa
            localStorage.setItem('name', response.data.nome); // nome de usuario
            localStorage.setItem('sector', response.data.sector);  // tipo usuario
            localStorage.setItem('typeUser',typeUser);
            if(response.data.token !== undefined){
            setRemoveLoading(false);
            
            console.log(response.data);            
            setUser(response.data);
            return(
               // navigate('../components/MenuSistem.js'),
                window.location.reload(),
                alert("Clique em 'Menu do Sistema'")
            )
            }else{
                alert(response.data);
                setRemoveLoading(false);
                window.location.reload()
            }

        } catch (error){
            if (!error?.response){
                setError('Erro ao acessar o servidor');
            } else if (error.response.status === 401) {
                setError('Usuário ou senha inválidos')
            }

        }
    }

    return(<>
{removeloading === false &&

    <Form >
       
    <h3>Login Colaboradores</h3>
    <Form.Group>
    <Form.Label>Email </Form.Label>
<Form.Control name="email" type='email' placeholder="insira um email cadastrado" value={Email} onChange={(e)=> setEmail(e.target.value)} />
<Form.Label>Senha</Form.Label>
<Form.Control name="password" type='password' value={Senha} onChange={(e) => setSenha(e.target.value)} />
</Form.Group>
<br/>
<Button onClick={handleClick}>Login</Button>



</Form>
}
{
    removeloading === true &&
    <Loader/>
}
</>
    )


}
 export default LoginEmployer