import React, { useEffect, useState } from "react";
import { Avatar, h4 } from "../../components/style";

import "../../App.css";
import Axios from "axios";
import api from "../../components/api";
import Loader from './../../components/loading/Loader';


function MyProfileEmployer(){
  var data = new Date();
var dia = String(data.getDate()).padStart(2, '0');
var mes = String(data.getMonth() + 1).padStart(2, '0');
var ano = data.getFullYear();
var dataAtual = dia + '/' + mes + '/' + ano;
const [user, setUser] = useState([]);
let IdEmployer = localStorage.getItem("userId");

const [removeLoader, setRemoveLoader] = useState(false);


useEffect(() => {
  api.get("/api/profileEmployer", 
 { params:{
  id: IdEmployer
  } }).then(({data}) =>{
      setUser(data)
      console.log(user);
      setRemoveLoader(true)
  })
}, [])

    return(
    <div className="areaConteudo">

    <h3>Meu perfil Colaborador</h3>
    {removeLoader === false && <Loader/>}
   {user.map(Item =>(
    <span key={Item.id}>

   
    <Avatar/>  
    <h4>Nome: {Item.nome}</h4><br/>
    <h4>Email Corporativo: {Item.email}</h4><br/>
    <h4>Telefone: {Item.telephone}</h4><br/>
    <h4>Celular: {Item.celular}</h4><br/>
    <h4></h4>
    <h4>Empresa: {Item.enterprise} </h4> <br/>   
    <h4>Data de Admissão: {Item.dataRegistro}</h4><br/>
    
    <h4>Setor: {Item.sector}</h4><br/>
    <h4>Função: {Item.funcao}</h4><br/>
    <h4>Salário: {Item.salario}</h4> <br/>
    <h4>Data: {dataAtual}</h4>
    </span>
   ))
}
    

    
    
    



    </div>
    
    )
}
  export default MyProfileEmployer