import {React} from "react";
import { Vagas, Avatar, Informations, Enunciate } from "../../components/style";
import '../../App.css';



function MyProfile(){


   

 

    let candidato = [
        {
            id:1,
            nome:'Joao',
            nascimento: '15/05/2023',
            rua: '5 de novembro',
            cidade: 'Sao Paulo',
            estado: 'Sao Paulo',
            numero: '653',
            pais: 'Brasil',
            avatar: './perfil.png',
            habilidades: 'html, css, javasccript, node',
            vagasCadastradas:[
                {
                    id:1,
                    nome: 'operador de sistemas',
                    empresa:'microsoft',
                    salario:'R$5.000',
                    contrato:'clt'

            },
            {
                id:2,
                nome: 'operador de manutenção',
                empresa:'JFX',
                salario:'R$2.500,00',
                contrato:'clt'

        },

        ]

        }

    ]
    
    return(
        <div>
            {candidato.map(candidact =>(<>
                <div key={candidact.id}>
                    <br/>
                    <h3>Meu Perfil</h3>
                    <br/>
                    <form className="areaConteudo">
                        <span>
                    <Avatar src={candidact.avatar}/>
                    <Enunciate>Nome: {candidact.nome}</Enunciate>
                    <Enunciate>Data de Nascimento:</Enunciate>
                    <Informations>{candidact.nascimento}</Informations>
                    <Enunciate>Rua:</Enunciate>
                    <Informations>{candidact.rua}</Informations>
                    <Enunciate>Cidade:</Enunciate>
                    <Informations>{candidact.cidade}</Informations>
                    <Enunciate>Estado:</Enunciate>
                    <Informations>{candidact.estado}</Informations>
                    <Enunciate>Pais:</Enunciate>
                    <Informations>{candidact.pais}</Informations>
                    </span>
                    {candidact.vagasCadastradas.map(item =>(
                        <>
                        <div key={item.id}></div>
                        <Vagas>{item.nome}</Vagas>
                        </>
                    ))}
                    </form>
                </div>
                </>


            ))}


        </div>
       
    )
}
 export default MyProfile