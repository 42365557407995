import styled from "styled-components";

export const IconBAr = styled.span`
font-size: 1.5rem;
margin-top: 15%;
margin-left: 10rem;
`;

export const NavBar = styled.div`
display: flex;
flex-flow: row wrap;
position: fixed;
background-color: #61dafb;
justify-content: center;
align-itens: center;
width: 100%;
height: 10vh;  
`;

export const Menu  = styled.ul`
list-style: none;
border: 1px solid #000000;
float: left;
`;

export const TEnunciate = styled.span`
display: inline-block;
position: flex;
background: #FFF;
margin-left: 5rem;

`;

export const AreaConteudo = styled.div`
flex-direction: row;
flex: 1;
display: inline-block;
margin-top: 15vh;
margin-left: 3%;
margin-right: 3%;
padding: 5%;
`;

export const Salved = styled.button`

display: block;
float: left;
background-color: #5CFDC2;
border-radius: 15px;
width: 5rem;
height: 3rem;
margin-left: 1rem;
display: block;
margin-top: 3rem;
`;

export const Cancel = styled.button`
display: block;
float: left;
background-color: #FF6347;
border-radius: 15px;
width: 5rem;
height: 3rem;
margin-left: 1rem;
display: block;
margin-top: 3rem;
`;

export const AdHabilit = styled.button`
background-color: #5CFDC2;
border-radius: 15px;
width: 15%;
height: 2rem;
margin-left: 1rem;
display: box;
font-size: 1rem;
`;

export const Logout = styled.button`
background-color: rgb(247, 198, 2 , 0.4);
border-color: #FFF;
border-radius: 25 px;
width: 4rem;
heigth: 5px;
display: block;
font-size: 0.98rem;

`;

export const Enunciate = styled.label`
fonte-size: 1.2rem;
margin-top: 2rem;
margin-bottom: 3%;
font-weight: bold;
`;

export const Input = styled.input`
width: 50%;
height: 40%;
border-radius: 15px;
font-size: 130%;
`;

export const HoleritInput = styled.input`
display: flex;
margin-top: 2rem;
width: 50%;
height: 40%;
border-radius: 15px;
font-size: 130%;

`;

export const BHora = styled.input`
float: left;
display: block;
width: 50%;
height: 40%;
border-radius: 15px;
font-size: 130%;
`;

export const Avatar = styled.img`
float: left;
display: block;
height: 5rem;
width: 5rem;
border: 1px solid;
margin-left: 1rem;
border-radius: 15px;
margin-left: 1rem;
margin-right: 2rem;
margin-rigth: 0.5rem;
`;

export const Vagas = styled.div`
float: left;
display: block;
border: 1px solid;
border-radius: 15px;
margin-left: 2rem;
margin-bottom: 1rem;
width: 15rem;
heigth: 15rem;
`;

export const Informations = styled.span`
display: block;
font-weight: bold; 
width: 50%;
height: 40%;
border-radius: 15px;
font-size: 130%;
margin-top: -2%;
`; 

export const InputHour = styled.select`
float: left;
display: block;
background-color: #ffd8c8;
margin-right: 10%;
`;

export const Option = styled.option`
background-color: #ffd8c8;
`;

export const AreaHistory = styled.div`
margin-top: 7rem;
border: 1px #000000;
display: flex;
`;

export const RegisterExit = styled.td`
color: #FF0000`;

export const IconProfile = styled.div`
align-itens: center;
align-self: center;
background-color: #FFFFFF;
border: 1px solid #81F781;
border-radius: 15px ;
heigth:10rem;
width: 11rem;

margin-bottom: 1rem;
margin-top:1rem;
padding: 2rem;
margin-left: 10%;
display: block;
float: left;
color:#000;
text-align:center;
`;

export const InformationArea = styled.div`
display: block;
float: left;
heigth: 20rem;
width: 25rem;
margin-top: 1rem;
margin-bottom: 10px;
margin-rigth: 2rem;
margin-left: 3rem;

`;
// terminar estilização de botão 
export const BuscaInfor  = styled.button`

`;

export const AreaCalculo = styled.div`
display: inline-block;
margin-top: 3rem;
margin-left: 3rem;
margin-top: 2rem;
align-itens: center;
font-size: 1.3rem;
font-weight: bold;


`;

export const AreaIcons = styled.div`
margin-top:5rem;

`;

export const SubAreaIcons = styled.div`

`;

export const IconPanel = styled.div`
justify-content: center;
align-itens: center;
text-align: center;
position: center;
font-size: 2.5rem;
color:#000;


`;

export const AreaCard = styled.div`
justify-content: center;
align-itens: center;
position: center;
margin-bottom: 1rem;
margin-top: 1rem;
`;

export const AreaLogin = styled.div`
margin-bottom: 1rem;
margin-top: 2rem;
justify-content: center;
align-itens: center;
display-flex: flex;
margin-bottom: 1rem;
position: center;
background-color: #FFFFFF;


`;