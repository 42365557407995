import React, { useState } from "react";



//import {HiOutlineOfficeBuilding} from 'react-icons/hi';

//import { Enunciate, Input, Salved } from "../components/style";
import LoginPj from "../login/LoginPj";
import LoginEmployer from "../login/LoginEmployer";
//import LoginAdmin from "./administration/loginAdmin";
//import { useDispatch } from "react-redux";
//import { changeUSer } from "../components/userSlice";
//import {HiOutlineUserGroup} from "react-icons/hi";
import '../App.css';



function Login(){

 
  ///  const dispatch = useDispatch();

    
const [ opcao, setOpcao] = useState(1);

function Opcoes(){
    if(opcao == 1){
        return(
            <LoginPj/>
        )

    }
    else 
    if(opcao == 2){
        return(
            <LoginEmployer/>
        )

    }
  
}





  

    return(
    
        <div className="areaConteudo">
           <select value={opcao} onChange={(e)=> setOpcao(e.target.value)}>
            <option value='1'>Empresas</option>
            <option value='2'>Colaboradores</option>
       

           </select>
        <Opcoes/>
        </div>
        
     
        
    )
}
export default Login