import React from "react";
import {  useState } from "react";
import Axios from "axios";
import { Enunciate, Input, Salved } from "../../components/style";
import "../../App.css"
//import { Router } from "react-router-dom";
//import api from "../../components/api";
import { Form, Button } from "react-bootstrap";


function AddEmployer(){

  // const baseUrl = "http://localhost:4000/api/adicionar"

    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    /*const [id, setId] = useState('');
    const [Post, setPost] = useState(null);
    const [index, setIndex] = useState();*/


    const user = {
        
        'Nome': Name,
        'Email': Email,
        'Senha': Password
    };

   function createUser(){
     Axios.post("http://vps50439.publiccloud.com.br:3000/api/adicionar", {
       nome : user.Nome,
       email: user.Email,
       senha: user.Senha       
      }).then((response) => {
        alert(response.data.msg);
        console.log(response);
      });
   }
/*
   function createUser(){
    api.post("/adicionar", {
        nome : user.Nome,
        email: user.Email,
        senha: user.Senha       
       }).then((response) => {
         alert(response.data.msg);
         console.log(response);
       });
    
   }*/
/*
    function confirmInformations(){
        return(
            alert('Dados a serem passados '+ Name, Email, Password )
        )
    }*/
 



 
    return(
    <> 

      <div className="areaConteudo">

    <h3>Cadastre um Funcionário  Para Acessar a Plataforma</h3>

    <Form>
    <Form.Label>Nome:  </Form.Label>
    <Form.Control type='text' name="name" value={Name} onChange={(e) => setName(e.target.value)} />
    <Form.Label>E-mail</Form.Label>
    <Form.Control type='email' name='email' value={Email} onChange={(e) => setEmail(e.target.value)} />
    <Form.Label>Senha</Form.Label>
    <Form.Control type='password' name='password' value={Password} onChange={(e) => setPassword(e.target.value) } /> <br/>
    <Button variant="success" onClick={createUser}>Salvar</Button>
  
    </Form>


    </div>

    </>
    )
}
 export default AddEmployer