import {React, useState } from "react";
// import { useForm } from "react-hook-form";
// import { useDropzone } from "react-dropzone";
//import { Form.Label, Form.Control, Button } from "../../components/style";
import  Axios  from "axios";
import '../../App.css';
import { Button, Form  } from "react-bootstrap";




function RegisterEmployer(){

    const IDenterprise = localStorage.getItem('enterpriseId');
    

    const [Photo, setPhoto] = useState("");
    const [Name, setName] = useState("");
    const [DataNascimento, setDatanascimento] = useState("");
    const [DataRegistro, setDataRegistro] = useState();
    const [Telephone, setPhone] = useState();
    const [Celular, setCelular] = useState();    
    const [Street, setStreet] = useState();
    const [Bairro, setBairro] = useState();
    const [Cep, setCep] = useState();
    const [Numero, setNumero] = useState();
    const [Email, setEmail] = useState();
    const [EmailCorporativo, setEmailCorporativo] = useState();
    const [State, setState] = useState();
    const [City, setCity] = useState();
    const [Country, setCountry] = useState();
    const [Rg, setRg] = useState();
    const [CopyRg, setCopyRg] = useState();
    const [Cpf, setCpf] = useState();
    const [CopyCpf, setCopyCpf] = useState();
    const [Stpf, setStpf] = useState();
    const [CopyCtps, setCopyCtps] = useState();
    const [Pis, setPis] = useState();
    const [TituloEleitor, setTituloEleitor] = useState();
    const [Cnh, setCnh] = useState();   
    const [CompResidence, setCopyResidence] = useState();   
    const [Certidao, setCertidao] = useState();
    const [CertidaoDependendis, setCertidaoDependensis] = useState();
    const [CopyReservista, setCopyReservista] = useState();
    const [AtestadoSaudeOcupacional, setAtestadoSaudeOcupacional] = useState();
    const [Senha, setSenha] = useState();
    const [sector, setSector] = useState();
    const [Salario, setSalario] = useState('');    
    const [funcao, setFuncao] = useState();

    const Enterprise= 'American';

        const Docs = {
            'idEnterprise': IDenterprise,
            'idphoto': Photo,
            'idnome': Name,
            'idDataNascimento': DataNascimento,
            'idDataRegistro': DataRegistro,
            'idtelephone': Telephone,
            'idCelular': Celular,
            'idStreet': Street,
            'idBairro': Bairro,
            'idCep': Cep,
            'idState': State,
            'idCity': City,
            'idNumero': Numero,
            'idEmail': Email,
            'idEmailCorporativo': EmailCorporativo,       
            'idCountry': Country,
            'idRg': Rg,
            'idCopyRg': CopyRg,
            'idCpf': Cpf,
            'idCopyCpf': CopyCpf,
            'idStpf': Stpf,
            'idCopyCtps': CopyCtps,
            'idPis': Pis,
            'idTituloEleitor': TituloEleitor,
            'idCnh': Cnh,
            'idCompResidence': CompResidence,
            'idCertidao': Certidao,
            'idCertidaoDependences': CertidaoDependendis,
            'idCopyReservista': CopyReservista,
            'idAtestadoSaudeOcupacional': AtestadoSaudeOcupacional,            
            'idSenha': Senha,
            'idSector': sector,
            'idSalario': Salario,
            'idFuncao': funcao      
        }; 

   
    

  //  const Form.ControlImage = useDropzone({  accept: ["image/jpeg", "image/pjpeg", "image/png", "image/gif"]})

    function handleEmployer(){    
        
        Axios.post("http://vps50439.publiccloud.com.br:3000/api/cadEmployer",{
            enterprise_id: Docs.idEnterprise,
            photo: Docs.idphoto,
            nome: Docs.idnome,
            dataNascimento: Docs.idDataNascimento,
            dataRegistro: Docs.idDataRegistro,               
            telephone: Docs.idtelephone,
            celular: Docs.idCelular,
            street: Docs.idStreet,
            bairro: Docs.idBairro,
            cep: Docs.idCep,
            numero: Docs.idNumero,
            email: Docs.idEmail,
            emailCorporativo: Docs.idEmailCorporativo,
            state: Docs.idState,
            city: Docs.idCity,
            country: Docs.idCountry,
            rg: Docs.idRg,
            copyRg: Docs.idCopyRg,
            cpf: Docs.idCpf,
            copyCpf: Docs.idCopyCpf,
            stpf: Docs.idStpf,
            pis: Docs.idPis,
            tituloEleitor: Docs.idTituloEleitor,
            cnh: Docs.idCnh,
            compResidencce: Docs.idCompResidence,
            certidao: Docs.idCertidao,
            certidaoDependencis: Docs.idCertidaoDependences,
            copyReservista: Docs.idCopyReservista,
            atestadoSaudeOcupacional: Docs.idAtestadoSaudeOcupacional,
            enterprise: Docs.idEnterprise,
            senha: Docs.idSenha,
            sector: Docs.idSector,
            salario: Docs.idSalario,
            funcao: Docs.idFuncao
        }).then((response) => {
            alert(response.data.msg)
            alert(response)
        });        


        
        
    }
    return(
    <div className="areaConteudo" >
        <h2>Registrar Novo Funcionário</h2>
        <b>Alguns recursos para envios de documentos ainda estão desabilitados, pedirmos para que por enquanto mantenha os aruqibvos em PDF e imagens salvos localmente</b>         
         <Form>
         <br/> <br/>
            <h3>Informações Pessoais</h3>
        <br/>
            
                <Form.Label>Foto 3 X 4</Form.Label>
            <Form.Control type='file' name="img" value={Photo} onChange={(e) => setPhoto(e.target.value)} />             
                
            
            
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control name="name" type='text' value={Name} onChange={(e) => setName(e.target.value)}  />
            
            
            <Form.Label>Data de Nascimento</Form.Label>
            <Form.Control name="data" type='date' value={DataNascimento} onChange={(e) =>setDatanascimento(e.target.value)} />
            <br/> <br/>
            
            <h3>Contato</h3>
            <br/> <br/>

            
                <Form.Label>Telefone fixo</Form.Label>
                <Form.Control name="telephone" type='text' value={Telephone} onChange={(e) => setPhone(e.target.value) }  />
            
            
                <Form.Label>Telefone celular</Form.Label>
                <Form.Control name="tellphone" type='tel' value={Celular} onChange={(e) => setCelular(e.target.value)} />
            
            
                <Form.Label>Email</Form.Label>
                <Form.Control value={Email} onChange={(e) =>setEmail(e.target.value)} />
                <br/> <br/>
                        
            <h3>Endereço</h3>
            <br/> <br/>
            
                <Form.Label>CEP</Form.Label>
                <Form.Control name="cep" type='number' value={Cep} onChange={(e) =>setCep(e.target.value)} />
            
            
                <Form.Label>Rua</Form.Label>
                <Form.Control name="street" value={Street} onChange={ (e) => setStreet(e.target.value)} />
            
            
                <Form.Label>Bairro</Form.Label>
                <Form.Control name="district" type='text' value={Bairro} onChange={(e) =>setBairro(e.target.value)}  />
            
            
                <Form.Label>Numero</Form.Label>
                <Form.Control value={Numero} onChange={(e )=>setNumero(e.target.value)} />
               
            
                <Form.Label>Cidade</Form.Label>
                <Form.Control value={City} onChange={(e)=>setCity(e.target.value)} />
                
                         
            
            <Form.Label>Estado</Form.Label>
            <Form.Control value={State} onChange={(e) =>setState(e.target.value)} />            
            
            
                <Form.Label>Pais</Form.Label>
                <Form.Control value={Country} onChange={(e) =>setCountry(e.target.value)} />
                <br/> <br/>
            
            <h3>Documentos</h3>
            <br/> <br/>
                <Form.Label>Comprovante de Residência</Form.Label> 
                <Form.Control type='file' value={CompResidence} onChange={setCopyResidence} />                
            
            
                <Form.Label>RG</Form.Label>
                <Form.Control name="rg" type='text' value={Rg} onChange={(e) => setRg(e.target.value)} />
                
                
                <Form.Label>Cópia do RG</Form.Label>
                <Form.Control name='copyRG' type='file' value={CopyRg} onChange={(e) => setCopyRg(e.target.value)} />
            
            
                <Form.Label>CPF</Form.Label>
                <Form.Control name="cpf"  type='text' value={Cpf} onChange={(e) => setCpf(e.target.value)} />
                
                
                     <Form.Label>Cópia do  CPF</Form.Label> 
                <Form.Control name="copyCpf" type='file' value={CopyCpf} onChange={(e) => setCopyCpf(e.target.value)} />             
                
                
                    <Form.Label>Numero da Carteira de Trabalho</Form.Label>
                    <Form.Control value={Stpf} onChange={(e) =>setStpf(e.target.value)}  />                    
                
                
                    <Form.Label>Cópia da Carteira de Trabalho</Form.Label>
                    <Form.Control type='file' value={CopyCtps} onChange={(e) =>setCopyCtps(e.target.value)} />
                

                
                    <Form.Label>Numero do PIS</Form.Label>
                    <Form.Control value={Pis} onChange={(e) =>setPis(e.target.value)} />
                
                
                    <Form.Label>Titulo de Eleitor</Form.Label>
                    <Form.Control value={TituloEleitor} onChange={(e) =>setTituloEleitor(e.target.value)} />
                
                
                    <Form.Label>Cópia da CNH</Form.Label>
                    <Form.Control type='file' value={Cnh} onChange={setCnh} />
                
                
                    <Form.Label>Cópia Certidão de Nasimento</Form.Label>
                    <Form.Control type='file' value={Certidao} onChange={setCertidao}  />
                
                
                    <Form.Label>Cópia Certidão de Nascimento de Dependentes</Form.Label>
                    <Form.Control type='file' value={CertidaoDependendis} onChange={setCertidaoDependensis} />
                
                
                    <Form.Label>Cópia de Reservista</Form.Label>
                    <Form.Control type='file' value={CopyReservista} onChange={setCopyReservista} />
                
                
                    <Form.Label>Atestado de Saúde Ocupacional</Form.Label>
                    <Form.Control type='file' value={AtestadoSaudeOcupacional} onChange={setAtestadoSaudeOcupacional} />
                    <br/>
                
                
                    <Form.Label>Setor</Form.Label>
                   <select value={sector} onChange={(e) => setSector(e.target.value)}>
                    <option value={null}>Selecione</option>
                    <option value='ti'>Tecnologia da informação</option>
                    <option value='rh'>Recursos Humanos</option>
                    <option value='auxAdm'> Administrativo</option>
                    <option value='engenharia' > Engenharia </option>
                   </select> <br/><br/>
                   Caso não tenha encontrado á cima, digite no campo abaixo <br/><br/>
                   <Form.Control value={sector} onChange={(e) => setSector(e.target.value)} />
                   <Form.Label>Função</Form.Label>
                   <Form.Control  value={funcao} onChange={(e) => setFuncao(e.target.value)} />
                
                
                    <Form.Label>Salário Base</Form.Label>
                    <Form.Control value={Salario} onChange={(e) => setSalario(e.target.value)} />
                    <br/> <br/>
                
                <h3>Informações de Acesso ao Sistema</h3>
                <br/> <br/>
                <Form.Label>Cadastrar Email Corporativo</Form.Label>
                <Form.Control value={EmailCorporativo} onChange={(e) =>setEmailCorporativo(e.target.value)} />
            
            
                <Form.Label>Senha</Form.Label>
                <Form.Control type='password'  value={Senha} onChange={(e) =>setSenha(e.target.value)} />
                <br/> <br/>
            
            <Button variant="success" onClick={handleEmployer}>Salvar</Button>
            </Form>
    </div>
    )
}
 export default RegisterEmployer