/* eslint-disable no-mixed-operators */
import React, { useState } from "react";
import { Route, Routes, Link} from "react-router-dom";
import { IconProfile, AreaIcons, IconPanel } from "./style";
import { ImProfile } from "react-icons/im";
// icones de registro
import {BsFillPersonPlusFill} from "react-icons/bs"; //cadastrar usuário
import {AiOutlineClockCircle} from "react-icons/ai"; // cartao ponto
import {FiEdit} from "react-icons/fi"; //cadastrar funcionario
//import {ImExit} from "react-icons/im"; // logout
import {GrDocumentTime} from "react-icons/gr"; //registrar ponto
import {FaUserCog} from "react-icons/fa"; // Colaboradores
//import {MdAttachMoney} from "react-icons/md" // folha pagamento
import {BsFillFileEarmarkSpreadsheetFill} from "react-icons/bs" // registrar e vizualizar holerites 
import {AiFillHome} from "react-icons/ai";
import {FaCoins} from "react-icons/fa";
import {FiUsers} from 'react-icons/fi';
import {FaRegEnvelope} from "react-icons/fa";
import {FiUserX} from "react-icons/fi";
import {FaUserTimes} from "react-icons/fa";
import {TbBuildingBank} from "react-icons/tb"

//rotas do sistema

//import Login from '../Pages/Login';
//import Vacancy from '../Pages/vacancy/Vacancy';
//import MyProfileEmployer from '../../Pages/employers/MyProfileEmployer';
import PointerRegister from '../Pages/employers/PointerRegister';
import EnterpriseProfile from '../Pages/enterprise/EnterpriseProfile';
import Collaborator from '../Pages/enterprise/Collaborator';
import PointerHistory from '../Pages/enterprise/PointerHistory';
import RegisterEmployer from '../Pages/employers/RegisterEmployer'; 
import Holerit from '../Pages/enterprise/holerit';
import Vholerite from '../Pages/employers/Vholerit';
//import MyProfile from '../Pages/employers/MyProfileEmployer';
import AddEmployer from '../Pages/employers/AddEmployer';
import CadastroPJ from "../Pages/CadastroPJ";
import '../App.css';
import Login from "../Pages/Login";
import { useNavigate } from "react-router-dom";
import MyProfileEmployer from '../Pages/employers/MyProfileEmployer';
import CadDesconts from "../Pages/enterprise/CadDesconts";
import ViewMessage from "../Pages/administration/ViewMessage";
import DeleteEmployer from "../Pages/enterprise/DeleteEmployer";
import DelCliente from "../Pages/administration/DelCliente";
import Reembolso from "../Pages/employers/reembolso";







function Access (){
   // const [id, setId] = useState('');
 //   const [typeUSer, setTypeUser] = useState('')
    

   /* useEffect(()=>{
        localStorage.getItem('userId')
        setTypeUser(localStorage.getItem('typeUser'))
        console.log(typeUSer)

    },[])*/

   
    const navigate = useNavigate()



   // const state = useSelector(state => state);
    const typeUSer = localStorage.getItem('typeUser'); 
    const sector = localStorage.getItem('sector');
    const nameEnterprise = localStorage.getItem("name");
    //const {signout} = useAuth();
   

    // exclui dados de login armazenados na memória
    function logout(){       
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        localStorage.removeItem("typeUser");
        localStorage.removeItem("sector");
        localStorage.removeItem("enterprise_id");

        window.location.reload()

        
       
        return(navigate("../Pages/Login.js") );
       



    }

    const Private = ({item}) => {
       const signed = false;

    return signed === true ? <item/> : <Login/>
};


if(typeUSer === 'enterprise' && nameEnterprise === 'rhally'){
    return(
        <AreaIcons>
        <Link to='../PAges/enterprise/EnterpriseProfile.js'><> <IconProfile><IconPanel><AiFillHome/></IconPanel>Vizualizar Perfil</IconProfile></></Link>
        <Link to='../Pages/enterprise/PointerHistory.js'><IconProfile><IconPanel><AiOutlineClockCircle/></IconPanel> Registro Cartão Ponto</IconProfile></Link>
        <Link to='../Pages/enterprise/holerit.js'><IconProfile><IconPanel><BsFillFileEarmarkSpreadsheetFill/></IconPanel>Cadastrar Holerite</IconProfile></Link>  
        <Link to ='../Pages/enterprise/CadDesconts.js'><IconProfile><IconPanel><FaCoins/></IconPanel>Adicionar Descontos</IconProfile></Link>        
        <Link to='../Pages/employers/RegisterEmployer.js'><IconProfile><IconPanel><BsFillPersonPlusFill/></IconPanel>Cadastrar Usuário</IconProfile></Link>
        <Link to='../Pages/enterprise/Collaborator.js'><IconProfile><IconPanel><FaUserCog/></IconPanel> Colaboradores</IconProfile></Link>
        <Link to='../Pages/enterprise/DeleteEmployer.js'><IconProfile><IconPanel><FiUserX/></IconPanel> Excluir Perfil de Colaborador</IconProfile></Link> 
        <Link to='../Pages/CadastroPJ' ><IconProfile><IconPanel><FiUsers/></IconPanel>Cadastrar Cliente</IconProfile></Link>
        <Link to='../Pages/administration/DelCliente.js'><IconProfile><IconPanel><FaUserTimes/></IconPanel>Exluir Perfil de Cliente</IconProfile></Link>
        <Link to="../Pages/administration/ViewMessage.js"><IconProfile><IconPanel><FaRegEnvelope/></IconPanel>Vizualisar Menssagens</IconProfile></Link>  
        </AreaIcons>
    )
}
    if (typeUSer === "enterprise"){
        return(
        <AreaIcons >

       <Link to='../PAges/enterprise/EnterpriseProfile.js'><> <IconProfile><IconPanel><AiFillHome/></IconPanel>Vizualizar Perfil</IconProfile></></Link>       
       <Link to='../Pages/employers/RegisterEmployer.js'><IconProfile><IconPanel><BsFillPersonPlusFill/></IconPanel>Cadastrar Usuário</IconProfile></Link>
       <Link to='../Pages/enterprise/Collaborator.js'><IconProfile><IconPanel><FaUserCog/></IconPanel> Colaboradores</IconProfile></Link>
       <Link to='../Pages/enterprise/DeleteEmployer.js'><IconProfile><IconPanel><FiUserX/></IconPanel> Excluir Perfil de Colaborador</IconProfile></Link> 
       <Link to='../Pages/enterprise/PointerHistory.js'><IconProfile><IconPanel><AiOutlineClockCircle/></IconPanel> Registro Cartão Ponto</IconProfile></Link>
       <Link to='../Pages/enterprise/holerit.js'><IconProfile><IconPanel><BsFillFileEarmarkSpreadsheetFill/></IconPanel>Cadastrar Holerite</IconProfile></Link>
       <Link to ='../Pages/enterprise/CadDesconts.js'><IconProfile><IconPanel><FaCoins/></IconPanel>Adicionar Descontos</IconProfile></Link> 
      
       </AreaIcons>
        )
        }
     else  if(typeUSer === 'employer' && sector ==='rh'){
        return(
        <AreaIcons >
       <Link to='../Pages/employers/MyProfileEmployer.js'><IconProfile><IconPanel><ImProfile/></IconPanel>Meu Perfil</IconProfile> </Link>
       <Link to='../Pages/employers/PointerRegister.js'><IconProfile><IconPanel><GrDocumentTime/></IconPanel>Registrar Ponto</IconProfile></Link>
       <Link to='../Pages/employers/Vholerit.js'><IconProfile><IconPanel><BsFillFileEarmarkSpreadsheetFill/></IconPanel>Vizualizar holerites</IconProfile></Link>       
       <Link to='../Pages/employers/RegisterEmployer.js'><IconProfile><IconPanel><BsFillPersonPlusFill/></IconPanel>Cadastrar Usuário</IconProfile></Link>
       <Link to='../Pages/enterprise/Collaborator.js'><IconProfile><IconPanel><FaUserCog/></IconPanel> Colaboradores</IconProfile></Link>
       <Link to='../Pages/enterprise/DeleteEmployer.js'><IconProfile><IconPanel><FiUserX/></IconPanel> Excluir Perfil de Colaborador</IconProfile></Link> 
       <Link to='../Pages/enterprise/PointerHistory.js'><IconProfile><IconPanel><AiOutlineClockCircle/></IconPanel> Registro Cartão Ponto</IconProfile></Link>
       <Link to='../Pages/enterprise/holerit.js'><IconProfile><IconPanel><BsFillFileEarmarkSpreadsheetFill/></IconPanel>Cadastrar Holerite</IconProfile></Link>  
       <Link to ='../Pages/enterprise/CadDesconts.js'><IconProfile><IconPanel><FaCoins/></IconPanel>Adicionar Descontos</IconProfile></Link>  
       <Link to='../Pages/employers/reembolso.jsx'><IconProfile> <IconPanel><TbBuildingBank/></IconPanel> Pedir Reembolsos </IconProfile> </Link>      
      </AreaIcons>
        )
    }
     else if(typeUSer === 'employer' &&  sector !=='rh'){
        return(
        <AreaIcons>
        <Link to='../Pages/employers/MyProfileEmployer.js'><IconProfile><IconPanel><ImProfile/></IconPanel>Meu Perfil</IconProfile> </Link>
        <Link to='../Pages/employers/PointerRegister.js'><IconProfile><IconPanel><GrDocumentTime/></IconPanel>Registrar Ponto</IconProfile></Link>
        <Link to='../Pages/employers/Vholerit.js'><IconProfile><IconPanel><BsFillFileEarmarkSpreadsheetFill/></IconPanel>Vizualizar holerites</IconProfile></Link>
        <Link to='../Pages/employers/reembolso.jsx'><IconProfile> <IconPanel><TbBuildingBank/></IconPanel> Pedir Reembolsos </IconProfile> </Link>   
       
        </AreaIcons>
        )
    }   
    else{
        return(
            <div className="areaConteudo">
            <h3>Opção não encontrada</h3>
            </div>
        )
    }
}

function MenuSistem(){
    let User = localStorage.getItem('name');

    return(        
        <>
        <br/><br/>
        <div className="areaConteudo">
        <h3>Bem Vindo {User}</h3>

        <Access/>
        </div>      

    <Routes>
    <Route path="../Pages/employers/MyProfileEmployer.js" element={<MyProfileEmployer/>} />
    <Route path="../Pages/enterprise/EnterpriseProfile.js" element={<EnterpriseProfile/>} />
    <Route path="../Pages/employers/AddEmployer.js" element={<AddEmployer/>} />
    <Route path="../Pages/employers/RegisterEmployer.js" element={<RegisterEmployer/>} />
    <Route path="../Pages/enterprise/Collaborator.js" element={<Collaborator/>} />
    <Route path="../Pages/employers/PointerRegister.js" element={<PointerRegister/>} />
    <Route path="../Pages/enterprise/PointerHistory.js" element={<PointerHistory/>}  />
    <Route path="../Pages/enterprise/holerit.js" element={<Holerit/>} />
    <Route path="../Pages/employers/Vholerit.js" element={<Vholerite/>} /> 
    <Route path="../Pages/enterprise/CadDesconts.js" element={<CadDesconts/>} />
    <Route path='Pages/CadastroPJ' element={<CadastroPJ/>} />
    <Route path="../Pages/administration/ViewMessage.js" element={<ViewMessage/>} />
    <Route path="../Pages/enterprise/DeleteEmployer.js" element={<DeleteEmployer/>} />
    <Route path="../Pages/administration/DelCliente.js" element={<DelCliente/>} />
    <Route path="../Pages/employers/reembolso.jsx" element={<Reembolso/>} />
    </Routes>

    <br/><br/>
        
        </>       
       
    )
} export default MenuSistem