import {React, useState, useEffect} from 'react';
import '../../App.css';
import { Vagas } from './../../components/style';
import api from '../../components/api';
import Axios from "axios";
import { Table, Modal, Button } from 'react-bootstrap';






function Collaborator(props){
    const Id_enterprise = localStorage.getItem('enterpriseId');
const enterprise = {
    "Enterprise" : Id_enterprise
};



    const [colab, setColab] = useState([]);

   

    useEffect(() =>{
       
        api.get("/api/Auser",{ params:{"enterprise_id" : enterprise.Enterprise}}).then(({data}) =>{
            setColab(data)
        })
    
    }, []);

    function handleColabEdit(id){
        return(
            alert("Editar perfil de " + id)
        )

    }

    function handleColabView(id){
        return(
            alert("ver Perfil de " + id)
        )

    }

/*
 useEffect(()=>{
    api.get("/todosEmployer").then(({data}) =>{
        setColab(data)
    }) 
    },[])

*/
//area modal
//constrole modal
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const hanldeShow = () => setShow(true);


//fim area modal
 

    

    

    return(<>
    <div className='areaConteudo'>
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar Funcionario</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>Fechar</Button>
                <Button variant='success'>Concluir edição</Button>
                <Button variant='danger'>Excluir Perfil </Button>
            </Modal.Footer>

        </Modal>
        <h3>Quadro de colaboradores</h3>
        <div>
            <Table striped bordered hover>
                <thead>

                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Função</th>
                    <th>ID de Registro</th>
                    <th>ID da empresa</th>
                </thead>
                <tbody>
            {colab.map(Colab =>(
               
                <tr key={Colab.id}>
                    
                     
                    <td>{Colab.nome}</td>
                    <td>{Colab.email}</td>
                    <td>{Colab.funcao}</td>
                    <td>{Colab.id}</td>
                    <td>{Colab.enterprise_id}</td>
                    <td><Button variant='success' onClick={(e)=>handleColabView(Colab.id, e)} >Ver informações</Button></td>
                    <td><Button variant='warning' onClick={(e)=>handleColabEdit(Colab.id, e)}>Editar Perfil</Button></td>
                    
                    

                </tr>
                
                
                

            ))}
            </tbody>
            </Table>
            <Button variant='primary' onClick={hanldeShow}>Abrir Modal</Button>
           
        </div>

    </div>
    
    </>)
} export default Collaborator