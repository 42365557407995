import {React, useState, useEffect} from "react";
//import { Form.Label, Form.Control, Salved } from "../components/style";
import Axios from "axios";
import { changeUSer } from "../components/redux/userSlice";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {Form, Button} from 'react-bootstrap'
//import { Button } from 'react-bootstrap';





function LoginPj(){
  const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [user, setUSer] = useState('');
    const [error, setError] = useState('');
    const [id, setID] = useState();
    const [name, setNameFantazi] = useState()
    const [key, setKey] = useState();
    const typeUser = 'enterprise'

    const dados = {
            'dadoEmail': email,
            'dadoSenha': senha
    }
    

    const dispatch = useDispatch();
  // função log armazena dados recebidos na requisição e envia ao redux
    function log(){
      setID(user.id);
      setNameFantazi(user.NomeFantasia);
      setKey(user.token);

     /* if(user.length>0){
      dispatch((changeUSer(key, id,name, typeUser)));
  

      } */
      
    }

    const handleLogin = async (e) =>{
        e.preventDefault();
      try {
        const response = await Axios.post('http://vps50439.publiccloud.com.br:3000/api/loginEnterprise',
        JSON.stringify({ email, senha}),
        {
         headers: {'Content-Type': 'application/json'} 
        }        
        );       
        //console.log(response.status);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('enterpriseId', response.data.enterpriseId);
        localStorage.setItem('name', response.data.NomeFantasia)
        localStorage.setItem('typeUser',typeUser);
        console.log(response.data.NomeFantasia)
        if( response.data.token !== undefined){     
         
          
          
          return(
          //navigate('../components/MenuSistem.js'),
          window.location.reload(),
          alert("Clique em 'Menu do Sistema'")
          
          )   

        }
        else{
          alert(response.data)
        }
        setUSer(response.data); 
        
     

      } catch (error) {
        if (!error?.response){
            setError("Erro ao accessar o servidor");
        } else if (error.response.status === 401){
            setError("Usuário ou senha inválidos")
        }
      }
    }

    return(
    <Form >
      <Form.Group>    
        <h3>Login Empresas</h3>    
       
        <Form.Label>Email Corporativo</Form.Label>
        <Form.Control type='email' name="email" value={email} onChange={(e)=> setEmail(e.target.value)} />
        <Form.Label>Senha</Form.Label>
        <Form.Control type='password' value={senha} onChange={(e) => setSenha(e.target.value)} />
        <br/>
        <Button variant="success" onClick={handleLogin}>Login</Button>
        </Form.Group>
        

    
    </Form>
    )
}
 export default LoginPj