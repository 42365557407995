import {React, useEffect, useState} from 'react';
import '../../App.css';
import api from '../../components/api';
import Loader from '../../components/loading/Loader';
import Axios from "axios";

function UserpriseProfile(){

    const Userprise_id = localStorage.getItem("enterpriseId");
    const [user, setUser] = useState([]);
    const [removeLoader, setRemoveLoader] = useState(false);
    
useEffect(() => {
    Axios.get("http://ec2-18-217-221-189.us-east-2.compute.amazonaws.com:3333/api/viewProfileEnterprise", 
   { params:{
    id: Userprise_id
    } }).then(({data}) =>{
        setUser(data)
        console.log(user);
        setRemoveLoader(true)
    })
  }, [])

    let Userprise=[
        {
            nome: 'RHInovar',
            cnpj: '459.9668.50001/95',
            endereco: [{
                cidade: "São Paulo",
                rua:"Campos Marinho",
                numero: '555',
                bairro: 'Cidade Alta',
                Estado:'São Paulo',
                cep: '16566896-000'
            }],
            email: 'rhinova@gmail.com',
            contato: '(11)99999-9999', 
            prof: '20',
            fatura: '116,60',

        }
    ]
    return(
    <div className='areaConteudo'>
        {user.map(User =>(<div key={User.id} >
            <h3>Meu Perfil</h3>
            <h4>Número de ID: {User.id}</h4>
        <h4>Nome Fantasia: {User.nome_fantasia} </h4>
        <h4>Razão Social: {User.razao_social}</h4>
        <h4>CNPJ: {User.cnpj} </h4>             
        <h4>Email: {User.email} </h4>
        <h4>Numero de Contato: {User.telephone} </h4>
        <h4>Cidade: {User.city}</h4>
        <h4>Estado: {User.state}</h4>
        <h4>Pais: {User.pais}</h4>
      
        
        </div>))}
        
    </div>)
}
 export default UserpriseProfile