import {React, useState, useEffect} from "react";
import api from "../../components/api";
import  '../../App.css';
import { AreaCalculo} from '../../components/style';
//import { AreaText } from './../styles';
import PdfVHolerit from "../../components/Reports/HoleritPdf/PdfVHolerit";
import { Button, Form, Row, Col, Table } from "react-bootstrap";

import Axios from "axios";



function Holerit(){

   
    const [mes, setMes] = useState('');
    let [funcionari, setFuncionari] = useState(); // recebe id de funcionario selecionado, repassa para requisiccao GET, usado também em requisição POST
       
    const [funcao, setFuncao] = useState('');
    const [salary, setSalary] = useState(0);
    const [horaExtra, setHoraExtra] = useState([]);
    const [persentExtra, setPercentExtra] = useState([]);
    const [infExtra, setInfExtra] = useState();
    const [infHora, setInfoHora] = useState();
    const [click, setClick] = useState('false');    
    let [idEmployer, setIdEmployer] = useState([]);
 //   const [option, setOption] = useState();
 //   const [onclick, setOnclick] = useState(0);
    const [SelectFuncionari, setSelectFuncionari] = useState([]);
    const [ScardPointer, setCardPointer] = useState([]);
// variaveis para agrupar arrays
    const [beneficios, setBeneficios] = useState([]);
 //   const [valuationBeneficios, setValuationBeneficios] = useState([]);
  
    const [descontos, setDescontos] = useState([]);
   // const [valuationDesccontos, setValuationDescontos] = useState([]);

    // variaveis para receber dados e serem copiados para array;
    const [Beneficios, set_Beneficios] = useState("");
    const [QtBeneficios, set_qtBeneficios] = useState();
    const [QTDescontos, setQTDescontos] = useState();
    const [Descontos, set_Descontos]= useState();
    // variaveis Form.Control beneficios;
    const [beneficios0, setBeneficios0]= useState('');
    const [beneficios1, setBeneficios1]= useState('');
    const [beneficios2, setBeneficios2]= useState('');
    const [beneficios3, setBeneficios3]= useState('');
    const [beneficios4, setBeneficios4]= useState('');
    //variaveis innput valor Beneficios;
    const [valorbeneficio0, setValorBeneficio0] = useState(0);
    const [valorbeneficio1, setValorBeneficio1] = useState(0);
    const [valorbeneficio2, setValorBeneficio2] = useState(0);
    const [valorbeneficio3, setValorBeneficio3] = useState(0);
    const [valorbeneficio4, setValorBeneficio4] = useState(0);
    // variaveis Form.Control descontos
    const [desconto0, setDesconto0] = useState();
    const [desconto1, setDesconto1] = useState();
    const [desconto2, setDesconto2] = useState();
    const [desconto3, setDesconto3] = useState();
    const [desconto4, setDesconto4] = useState();
    // variaveis Form.Control valor de descontos
    const [valorDescont0, setValorDescont0] = useState(0);
    const [valorDescont1, setValorDescont1] = useState(0);
    const [valorDescont2, setValorDescont2] = useState(0);
    const [valorDescont3, setValorDescont3] = useState(0);
    const [valorDescont4, setValorDescont4] = useState(0);
    //variaveis Form.Control vencimentos
    const [vencimento0, setVencimento0] = useState();
    const [vencimento1, setVencimento1] = useState();
    const [vencimento2, setVencimento2] = useState();
    const [vencimento3, setVencimento3] = useState();
    const [vencimento4, setVencimento4] = useState();
    // variavie sForm.Control valor de vencimentos
    const [valorVencimento0, setValorVencimento0] = useState(0);
    const [valorVencimento1, setValorVencimento1] = useState(0);
    const [valorVencimento2, setValorVencimento2] = useState(0);
    const [valorVencimento3, setValorVencimento3] = useState(0);
    const [valorVencimento4, setValorVencimento4] = useState(0);

    const [totalValueBeneficio, setTotalValueBeneficio] = useState();
    const [totalValueVencimento, setTotalValueVencimento] = useState();
    const [totalValueDesconto, setTotalValueDescont] = useState();

    const[Inss, setInss] =  useState();
    const [Dsr, setdsr] = useState();
    const [totalHoras, setTotalHoras] = useState();


    //total salario liquido
    const [salaryLiquid, setSalaryLiquid] = useState();
    let Colaborator = SelectFuncionari.map(function(element){
        return `${element.id} ${element.salario}`
    })


    // calculo de vencimento
    useEffect(()=>{
        setTotalValueVencimento(parseFloat(valorVencimento0) + parseFloat(valorVencimento1)+ parseFloat(valorVencimento2)+parseFloat(valorVencimento3)+parseFloat(valorVencimento4))

    },[valorVencimento0,valorVencimento1, valorVencimento2, valorVencimento3, valorVencimento4])

    // cauculo de beneficios
    useEffect(()=>{
        setTotalValueBeneficio(parseFloat(valorbeneficio0)+ parseFloat(valorbeneficio1)+ parseFloat(valorbeneficio2)+ parseFloat(valorbeneficio3)+ parseFloat(valorbeneficio4))
    },[valorbeneficio0, valorbeneficio1, valorbeneficio2, valorbeneficio3, valorbeneficio4])

    //cauculo de descontos
    useEffect(()=>{
        setTotalValueDescont(parseFloat(valorDescont0) + parseFloat(valorDescont1)+ parseFloat(valorDescont2)+ parseFloat(valorDescont3)+ parseFloat(valorDescont4))
    },[valorDescont0, valorDescont1, valorDescont2, valorDescont3, valorDescont4])

    //cauculo salario liquido
    useEffect(()=>{
        setSalaryLiquid(parseFloat(salary)+parseFloat( totalValueBeneficio)+parseFloat( totalValueVencimento)-parseFloat(totalValueDesconto))
       
    },[totalValueBeneficio, totalValueDesconto, totalValueVencimento, salary])

    // lista para adicionar beneficios em vetores
  


   // useEffect
   function ViewHolerit(){
    let [onPointer, SetPointer] = useState([])

useEffect(() =>{
       
    api.get("/api/viewPointer").then(({data}) =>{
        SetPointer(data)
    });

}, [onPointer]) // passar id de empresa, user filter para obter apenas o mês de fechamento de holerite
    

   }  // setPointer, onPointer
  let ID_enterprise  = localStorage.getItem("enterpriseId")


    // eslint-disable-next-line react-hooks/rules-of-hooks
    

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        api.get("/api/Auser", 
       { params:{
        enterprise_id: ID_enterprise

        } }).then(({data}) =>{
            setIdEmployer(data);
           
        })
    }, []);   
  
   

   function addExtra(){
    if(click === 'false'){
        alert("não clicou")
        function adicioner(){
            setClick ('true')
        }
        return(setClick)
    }
    else{
        alert("clicou")
        
    }
   };

  

   function handleClick(){
    alert('Voce selecionou ')
   }
// funcao salvar envia os dados para api registrar holeite no banco e dados

const itens = {
    'Enterprise': ID_enterprise,
    'Funcionari': funcionari,
    'DescriptionDesconto1': desconto0,
    'DescriptionDesconto2': desconto1,
    'DescriptionDesconto3': desconto2,
    'DescriptionDesconto4': desconto3,
    'DescriptionDesconto5': desconto4,
    'Desconto1': valorDescont0,
    'Desconto2': valorDescont1,
    'Desconto3': valorDescont2,
    'Desconto4': valorDescont3,
    'Desconto5': valorDescont4,
    'DescriptionVencimento1': vencimento0,
    'DescriptionVencimento2': vencimento1,
    'DescriptionVencimento3': vencimento2,
    'DescriptionVencimento4': vencimento3,
    'DescriptionVencimento5': vencimento4,
    'Vencimento1': valorVencimento0,
    'Vencimento2': valorVencimento1,
    'Vencimento3': valorVencimento2,
    'Vencimento4': valorVencimento3,
    'Vencimento5': valorVencimento4,
    'DescriptionBeneficio1': beneficios0,
    'DescriptionBeneficio2': beneficios1,
    'DescriptionBeneficio3': beneficios2,
    'DescriptionBeneficios4': beneficios3,
    'DescriptionBeneficios5': beneficios4,
    'ValueBeneficio1': valorbeneficio0,
    'ValueBeneficio2': valorbeneficio1,
    'ValueBeneficio3': valorbeneficio2,
    'ValueBeneficio4': valorbeneficio3,
    'ValueBeneficio5': valorbeneficio4,
    'SalaryBase': salary,
    'valueInss': Inss,
    'valueDsr': Dsr,
    'TotalHorasExtras': horaExtra,
    'TotalDescontos': totalValueDesconto,
    'TotalTimeWork': totalHoras,
    'VencimentosTotais': totalValueVencimento,
    'Salaryliquid': salaryLiquid,
};
   function salvar(){
    Axios.post("http://ec2-18-217-221-189.us-east-2.compute.amazonaws.com:3333/api/api/newHolerit",{
        enterprise_id: itens.Enterprise ,
        employer_id: itens.Funcionari,
        descriptionDesc1: itens.DescriptionDesconto1,
        descriptionDesc2: itens.DescriptionDesconto2,
        descriptionDesc3: itens.DescriptionDesconto3,
        descriptionDesc4: itens.DescriptionDesconto4,
        desc1: itens.Desconto1,
        desc2: itens.Desconto2,
        desc3: itens.Desconto3,
        desc4: itens.Desconto4,
        desc5: itens.Desconto5,
        descriptionVencimento1: itens.DescriptionVencimento1,
        desccriptionVencimento2: itens.DescriptionVencimento2,
        descriptionVencimento3: itens.DescriptionVencimento3,
        descriptionVencimento4: itens.DescriptionVencimento4,
        descriptionVencimento: itens.DescriptionVencimento5,
        vencimento1: itens.Vencimento1,
        vencimento2: itens.Vencimento2,
        vencimento3: itens.Vencimento3,
        vencimento4: itens.Vencimento4,
        vencimento5: itens.Vencimento5,
        descriptionBeneficio1: beneficios0,
        desccriptionBeneficio2: beneficios1,
        descriptionBeneficio3: beneficios2,
        descriptionBeneficio4: beneficios3,
        descriptionBeneficio5: beneficios4,
        beneficio1: itens.ValueBeneficio1,
        beneficio2: itens.ValueBeneficio2,
        beneficio3: itens.ValueBeneficio3,
        beneficio4: itens.ValueBeneficio4,
        beneficio5: itens.ValueBeneficio5,
        salarioBase: itens.SalaryBase,
        inss: itens.valueInss,
        dsr: itens.valueDsr,
        horasHextras: itens.TotalHorasExtras,
        totalDescontos: itens.TotalDescontos,
        totalHorasTrabalhadas: itens.TotalTimeWork,
        totalVencimentos: itens.VencimentosTotais,
        salarioLiquido: itens.Salaryliquid                               
    }).then((response) => {
     //   alert(response.data.msg)
        alert(response.data);
        window.location.reload()
    });

   } 

   function handleSelect(){
    return(
    api.get("/api/EmployerLocale", {
        params:{
            id: funcionari
        }
    }).then(({data}) =>{
        setSelectFuncionari(data);
        data.filter((resp)=>setSalary(resp.salario))
    })
    )
   };

   //localiza registro de ponto eletronico do funcionario

   function cardPointer(){
    if(funcionari){
    return(
        api.get("api/viewPointer", {
            params:{'employer_id': funcionari}
        }).then(({data}) =>{
            setCardPointer(data)
        })     
    )
    }
    else if(!funcionari){
        return( alert("Insira um valor válido"))    
    }
   }
   let totalcard = ScardPointer.length;

   // funcão para fechamento de holerit  

   function calcularSalario(){


   }


    return(
    <>
    <div className="areaConteudo">
        <Form>
        <h3>Cadastrar holerites</h3>
        <br/><br/>
        <Form.Label>Selecione o funcionário</Form.Label>   
        <select value={funcionari} onChange={(e) => setFuncionari(e.target.value)} >
        <option value='0'>Selecione</option>
            {idEmployer.map(IdEmployer =>(
                <option key={IdEmployer.id} value={IdEmployer.id} onClick={()=>{setSalary(IdEmployer.salario)}}>                   
                   {IdEmployer.nome}
                </option>  
            ))}
        </select>
        <br/> 
        <Button variant="info" onClick={handleSelect} >Selecionar</Button> <br/><br/><br/>    
     
        <Form.Label>Mês e ano</Form.Label>
        <Form.Control name="month" type='month' value={mes} onChange={(e) =>{setMes(e.target.value)}} />
        <br/>

        <Form.Label>Vizualizar Cartão Ponto <Button variant="success" onClick={cardPointer}>Cartao ponto</Button ></Form.Label>
        <br/>

        {totalcard >0 &&
       
            <Table striped bordered hover>
                <thead>
            <tr>
                <th>Numero de Registro</th>
                <th>Data</th>
                <th>Entrada Primeiro Periodo</th>
                <th>Saida Primerio Periodo</th>
                <th>Entrada Segundo Periodo</th>
                <th>Saida Segundo Periodo</th>
            </tr>
            </thead>
            <tbody>
            {ScardPointer.map(item =>(<tr key={item.id} >            
                <td> {item.id}</td>
                <td>
                {item.dia}
                </td>
                <td>
                    {item.entrada_1_period}
                </td>
                <td>
                    {item.saida_1_period}
                </td>
                <td>
                    {item.entrada_2_period}
                </td>
                <td>
                    {item.saida_2_priod}
                </td> 
            </tr>
             ))}
             </tbody>


            </Table>
}

        {SelectFuncionari.map(Employer =>(<div key={Employer.id}>
            <Form.Group>
                <Row>
                    <Col>
        
            <Form.Label>Função: </Form.Label>
            <Form.Control type='text' value={Employer.funcao} onChange={(e) =>{setFuncao(e.target.value)}} />
            </Col>
            <Col>        
            
            <Form.Label>Salario Base: </Form.Label>
            <Form.Control type='number' value={salary} onChange={(e) =>{setSalary(e.target.value)}} />
            </Col>
            </Row>
            </Form.Group>
            
            
            </div>))}
            <br/><br/>
           
 
       <div> <h3>Horas extras</h3></div>
        <br/><br/>
        <Form.Group>
        <Row>
            <Col>
      
        <Form.Label>% Hora</Form.Label> 
        <Form.Control name='porcent' value={infExtra}  onChange={(e) =>{setInfExtra(e.target.value)}}/>
       
        </Col>
        <Col>
        
        <Form.Label>Quantidade</Form.Label>
        <Form.Control type='number' value={infHora} onChange={(e) =>{setInfoHora(e.target.value)}} /><br/><br/>
        
        </Col>
        </Row>
        </Form.Group>
        <Button variant="secondary" onClick={addExtra}>Adicionar</Button>
      
        <br/>
        <h3>Vencimentos</h3>
        <div>
            <Row>
                <Col>
            
        
            <Form.Label>Vencimento</Form.Label>
            <Form.Control type='text' value={vencimento0} onChange={(e)=>{setVencimento0(e.target.value)}} />
            </Col>
            <Col>   
        
            <Form.Label>Valor do Vencimento</Form.Label>
            <Form.Control type='number' value={valorVencimento0} onChange={(e)=>{setValorVencimento0(e.target.value)}} />
            </Col>
            </Row>
        
        </div>
        <Row>
            <Col>        
            <Form.Label>Vencimento</Form.Label>
            <Form.Control type='text' value={vencimento1} onChange={(e)=>{setVencimento1(e.target.value)}} />
            </Col>
            <Col>           
            <Form.Label>Valor do Vencimento</Form.Label>
            <Form.Control type='number' value={valorVencimento1} onChange={(e)=>{setValorVencimento1(e.target.value)}} />
            </Col>        
        </Row>
        <Row>
            <Col>        
            <Form.Label>Vencimento</Form.Label>
            <Form.Control type='text' value={vencimento2} onChange={(e)=>{setVencimento2(e.target.value)}} />
            </Col>
            <Col>           
            <Form.Label>Valor do Vencimento</Form.Label>
            <Form.Control type='number' value={valorVencimento2} onChange={(e)=>{setValorVencimento2(e.target.value)}} />
            </Col>        
        </Row>
        <Row>
            <Col>        
            <Form.Label>Vencimento</Form.Label>
            <Form.Control type='text' value={vencimento3} onChange={(e)=>{setVencimento3(e.target.value)}} />
            </Col>
            <Col>        
            <Form.Label>Valor do Vencimento</Form.Label>
            <Form.Control type='text' value={valorVencimento3} onChange={(e)=>{setValorVencimento3(e.target.value)}} />
            </Col>        
        </Row>
        <Row>
            <Col>        
            <Form.Label>Vencimento</Form.Label>
            <Form.Control type='text' value={vencimento4} onChange={(e)=>{setVencimento4(e.target.value)}} />
            </Col>
            <Col>        
            <Form.Label>Valor do Vencimento</Form.Label>
            <Form.Control type='text' value={valorVencimento4} onChange={(e)=>{setValorVencimento4(e.target.value)}} />
            </Col>      
        </Row>
               
        <AreaCalculo> Total de Vencimentos: R${totalValueVencimento}
        </AreaCalculo>
        <br/><br/>
        <h3>Beneficios</h3>
        <Row>
            <Col>
            <Form.Label>Beneficio</Form.Label> 
            <Form.Control type='text' value={beneficios0} onChange={(e) =>{setBeneficios0(e.target.value)}} />  
            </Col> 
            <Col>  
            <Form.Label>Valor</Form.Label>
             <Form.Control type='number' value={valorbeneficio0} onChange={(e)=>{setValorBeneficio0(e.target.value)}} /> 
             </Col>
            
        </Row>
        <Row>
            <Col>
            
            <Form.Label>Beneficio</Form.Label> 
            <Form.Control type='text' value={beneficios1} onChange={(e) =>{setBeneficios1(e.target.value)}} /> 
            </Col>
            <Col>
            <Form.Label>Valor</Form.Label>
             <Form.Control type='number' value={valorbeneficio1} onChange={(e)=>{setValorBeneficio1(e.target.value)}} />
             </Col>
            
        </Row>
        <Row>
            <Col>
            
            <Form.Label>Beneficio</Form.Label> 
            <Form.Control type='text' value={beneficios2} onChange={(e) =>{setBeneficios2(e.target.value)}} />    
            </Col>
            <Col> 
            <Form.Label>Valor</Form.Label>
             <Form.Control type='number' value={valorbeneficio2} onChange={(e)=>{setValorBeneficio2(e.target.value)}} /> 
             </Col>
            
        </Row>
        <Row>
            <Col>           
            <Form.Label>Beneficio</Form.Label> 
            <Form.Control type='text' value={beneficios3} onChange={(e) =>{setBeneficios3(e.target.value)}} />       
            </Col>
            <Col>  
            <Form.Label>Valor</Form.Label>
             <Form.Control type='number' value={valorbeneficio3} onChange={(e)=>{setValorBeneficio3(e.target.value)}} /> 
             </Col>
        </Row>
        <Row>
            <Col>           
            <Form.Label>Beneficio</Form.Label> 
            <Form.Control type='text' value={beneficios4} onChange={(e) =>{setBeneficios4(e.target.value)}} />       
            </Col>   
            <Col>           
            <Form.Label>Valor</Form.Label>
             <Form.Control type='number' value={valorbeneficio4} onChange={(e)=>{setValorBeneficio4(e.target.value)}} /> 
             </Col>
            
        </Row>
        <AreaCalculo>Total de Beneficios: R${totalValueBeneficio}</AreaCalculo>

      
        
        <h3>Descontos</h3> <br/>
        {
        descontos.map((Item, index) =>{<div key={index}>{Item.Descontos}</div>})}
        <Row>
            <Col>
            <Form.Label>Desconto</Form.Label> <br/>
            <Form.Control type='text' value={desconto0} onChange={(e) =>{setDesconto0 (e.target.value)}} /> 
            </Col>
            <Col> 
            <Form.Label>Valor do Desconto</Form.Label> <br/>
             <Form.Control type='number' value={valorDescont0} onChange={(e) =>{setValorDescont0(e.target.value)}} />
             </Col>
        </Row>
        <Row>
            <Col>            
            <Form.Label>Desconto</Form.Label> <br/>
            <Form.Control type='text' value={desconto1} onChange={(e) =>{setDesconto1 (e.target.value)}} /> 
            </Col>
            <Col> 
            <Form.Label>Valor do Desconto</Form.Label> <br/>
             <Form.Control type='number' value={valorDescont1} onChange={(e) =>{setValorDescont1(e.target.value)}} />
             </Col>
            
        </Row>
        <Row>
            <Col>
            
            <Form.Label>Desconto</Form.Label> <br/>
            <Form.Control type='text' value={desconto2} onChange={(e) =>{setDesconto2 (e.target.value)}} /> 
            </Col>
            <Col>        
            
            <Form.Label>Valor do Desconto</Form.Label> <br/>
             <Form.Control type='number' value={valorDescont2} onChange={(e) =>{setValorDescont2(e.target.value)}} />
             </Col>
            
        </Row>
        <Row>
            <Col>           
            <Form.Label>Desconto</Form.Label> <br/>
            <Form.Control type='text' value={desconto3} onChange={(e) =>{setDesconto3 (e.target.value)}} /> 
            </Col>
            <Col>                    
            <Form.Label>Valor do Desconto</Form.Label> <br/>
             <Form.Control type='number' value={valorDescont3} onChange={(e) =>{setValorDescont3(e.target.value)}} />
             </Col>            
        </Row>
        <Row>
            <Col>            
            <Form.Label>Desconto</Form.Label> <br/>
            <Form.Control type='text' value={desconto4} onChange={(e) =>{setDesconto4 (e.target.value)}} /> 
            </Col>
            <Col>
            <Form.Label>Valor do Desconto</Form.Label> <br/>
             <Form.Control type='number' value={valorDescont4} onChange={(e) =>{setValorDescont4(e.target.value)}} />
             </Col>            
        </Row>

        <AreaCalculo>Total de Descontos: {totalValueDesconto}</AreaCalculo> <br/>
        <AreaCalculo>Salário Líquido: R${salaryLiquid}</AreaCalculo>
        <br/><br/>

        <Button onClick={salvar}>Salvar</Button>
        </Form>
        

    </div>
    {/*Vizualizar informações salvas no holerit*/}
    </>
    )
}
   export default Holerit