import React from "react";
import '../../App.css';

function Vacancy( ){


    return(
        <>
        <h2>Veja aqui as vagas disponíveis em nossa plataforma</h2>
        </>
    )
}
  export default Vacancy