import {React, useState} from "react";
import { useEffect } from "react";

import { Enunciate, Salved, InputHour, Input} from './style';
import Axios from "axios";
import '../App.css';
import api from '../components/api';
import { Button } from "react-bootstrap";

function RegisterPointer() {

    var dateString = new Date().toLocaleString("pt-BR", {timezone: 'America/Sao_Paulo'});
    var formattesString = dateString.replace(" , ", " - ");
    var time = formattesString
    setInterval(time, 1000)
    var dia = new Date();
    //var dia = String(data.getDate()).padStart(2, '0');

    const hora = new Date().toLocaleTimeString();
    const [Option, setOption] = useState()
 //   const [hour, setHour] = useState({time});
    let [onSearch, setOnSearch] = useState(0);
  //  let [onData, setOnDate] = useState('');
  const [lastPointer, setLastPointer]  =useState();

 const employer_id = localStorage.getItem('userId');    
    const enterprise_id = localStorage.getItem('enterpriseId');
    const authorization = localStorage.getItem("token")
    //const funcionar = 'Jose';
    let entrada_1_period = hora
  
    const itens = {
        'Employer': employer_id,       
        'Hour': hora,
        'Data': dia       
    };   

    const [colab, setColab] = useState([]);
    const [position, setPosition] = useState([]);   

    useEffect(() =>{
       
        api.get("api/firstPointer", {
            params:{'employer_id': employer_id}
        }).then(({data}) =>{
            setColab(data)
        });
    
    }, [])

    let total = colab.length;
    const handleConfirm= async(e) =>{           
        // eslint-disable-next-line default-case
        switch(Option){
            case '1': 
            alert('Opção não cadastrada');
            console.log(authorization)
            break;
            // eslint-disable-next-line no-fallthrough
            case '2':     //cadastra entrada primeiro horario
            e.preventDefault()
            try{
              const response = await Axios.post("http://vps50439.publiccloud.com.br:3000/api/pointer",
              JSON.stringify({employer_id, enterprise_id, dia, entrada_1_period}),
              {
                headers:{'Content-Type': 'application/json' }
              });
              api.get("/api/firstPointer", {
                params:{'employer_id': employer_id}
            }).then(({data}) =>{
                setColab(data)                
            });

              alert(colab.id)
              /*  employer_id: itens.Employer,
                enterprise_id: itens.Enterprise,               
                dia: itens.Data,
                entrada_1_period: itens.Hour,  */                         
            }
          catch(error){
            console.log(error)
          }


           
            

                break;
                case '3': //cadastra saida primeiro horario
                    alert (lastPointer)
                    
                /*   Axios.put("http://localhost:4000/api/addPointer",{
                        params:{                        
                       id: lastPointer,
                       saida_1_period: itens.Hour
                        }
                        
                                   
                    }).then(({response}) => {
                        alert(response.data.msg)
                        alert({response})
                    }); */

                    fetch('http://vps50439.publiccloud.com.br:3000/api/addPointer', {
                        method: 'PUT',
                        body: JSON.stringify({
                          id: lastPointer,
                          saida_1_period: itens.Hour
                        }),
                        headers: {
                          'Content-type': 'application/json; charset=UTF-8',
                        },
                      })
                        .then((response) => response.json())
                        .then((json) => console.log(json));
                    break;
                    case '4': //cadastra entrada segundo horario
                        alert('Salvar em opção: ' + Option);
                        fetch('http://vps50439.publiccloud.com.br:3000/api/addPointer', {
                            method: 'PUT',
                            body: JSON.stringify({
                              id: lastPointer,
                              entrada_2_period: itens.Hour
                            }),
                            headers: {
                              'Content-type': 'application/json; charset=UTF-8',
                            },
                          })
                            .then((response) => response.json())
                            .then((json) => console.log(json));
                        break;
                        case '5': //cadastra saida segundo horario
                            fetch('http://vps50439.publiccloud.com.br:3000/api/addPointer', {
                                method: 'PUT',
                                body: JSON.stringify({
                                  id: lastPointer,
                                  saida_2_priod: itens.Hour
                                }),
                                headers: {
                                  'Content-type': 'application/json; charset=UTF-8',
                                },
                              })
                                .then((response) => response.json())
                                .then((json) => console.log(json));
                            break;
        }   

        }
   
    return(
    <div>
    <Enunciate>Registrar Ponto</Enunciate> {total}
    <label>ID operação</label>
    <input value={lastPointer} onChange={(e) => setLastPointer(e.target.value)} />
    
    <Enunciate>Selecione o periodo</Enunciate>
    <InputHour value={Option} onChange={(e) => setOption(e.target.value)}>
    <option value='1'>Selecione</option>
    <option value='2'>Entrada 1° Periodo</option>
    <option value='3'>Saida 1° Periodo</option>
    <option value='4'>Entrada 2° Periodo</option>
    <option value='5'>Saida 2° Periodo</option>
    </InputHour>
    <Enunciate>nome:</Enunciate>
    <br/><br/>
  
    <Button variant="success" onClick={handleConfirm}>Registrar ponto</Button> 
    </div>);
} export default RegisterPointer