import styled from "styled-components";

export  const AreaText = styled.div`
background: #456854;
`;

export const Footer = styled.footer`

margin-top: 15rem;
text-align: center;
bakground-color: #0035a6;
color: #FFF;
heigth: 100%;
width: 100%;

`