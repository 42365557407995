import {configureStore} from '@reduxjs/toolkit';
import userReducer from './userSlice';


export default configureStore({
    reducer:{
        key: userReducer,
        id: userReducer,
        name: userReducer,
        typeUser: userReducer,
        isLogged: userReducer

    }
})