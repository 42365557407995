/*import logo from './logo.svg'; */
import { useEffect, useState } from 'react';

import { Footer } from './Pages/styles';
import { BrowserRouter, Link, Routes, Route } from 'react-router-dom';
import './App.css';
import { IconBAr, Logout } from './components/style';
import {AiTwotoneAppstore} from 'react-icons/ai';
import { useSelector } from 'react-redux';
import Home from './Pages/Home';
import CadastroCAndidato from './Pages/CadastroCandidato';
import CadastroPJ from './Pages/CadastroPJ';
import Login from './Pages/Login';
import MyProfile from './Pages/myProfile/MyProfile';
import Vacancy from './Pages/vacancy/Vacancy';
import AddEmployer from './Pages/employers/AddEmployer';
import MyProfileEmployer from './Pages/employers/MyProfileEmployer';
import PointerRegister from './Pages/employers/PointerRegister';
import EnterpriseProfile from './Pages/enterprise/EnterpriseProfile';
import Collaborator from './Pages/enterprise/Collaborator';
import PointerHistory from './Pages/enterprise/PointerHistory';
import RegisterEmployer from './Pages/employers/RegisterEmployer';
import Holerit from './Pages/enterprise/holerit';
import MenuSistem from './components/MenuSistem';
import Vholerite from './Pages/employers/Vholerit';
import { AuthProvider } from './context/auth';
import useAuth from './hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Button, Dropdown,Card } from 'react-bootstrap';
import Logo from './Assets/images/logo.png';
import LoginPj from './login/LoginPj';
import LoginEmployer from './login/LoginEmployer';
import Contact from './Pages/contact/Contact';
import CadDesconts from './Pages/enterprise/CadDesconts';
import ViewMessage from './Pages/administration/ViewMessage';
import  DeleteEmployer  from './Pages/enterprise/DeleteEmployer';
import DelCliente from './Pages/administration/DelCliente';
import FolhaPagamento from './Pages/enterprise/FolhaPagamento';
import Reembolso from './Pages/employers/reembolso';




function App() {
 

  const state = useSelector(state => state)
  const [isloged, setIsloged] = useState(false);
  const [signin, setSignin] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
/*
 const isLoged = () =>{
  if(state.key == null){
   return (false);
  }
  else{
    return (true)
  }
}
*/

 // let loged = isLoged
 
  
 
 const Token = localStorage.getItem('token')
 useEffect(()=>{
  
  if (Token === null){
    console.log("token reuperado:" + Token)
    
    setIsloged(false) 
    //window.location.reload()
  }
  else{
   setIsloged(true)
   console.log(Token)
   console.log("token reuperado:" + Token)
   //setSignin (1)

  }

 },[])


 
  let typeUser = state.typeUser;
  let sector = 'rh'; // requisiçãõ para filtrar sector de funcionario
  var data = new Date();
var dia = String(data.getDate()).padStart(2, '0');
var mes = String(data.getMonth() + 1).padStart(2, '0');
var ano = data.getFullYear();
var dataAtual = dia + '/' + mes + '/' + ano;
//console.log(isLoged)


/*
const Private = ({Item}) => {
  const {login} = state.isloged;

  return login === true ? <MenuSistem/> : <Login/>
};
*/
const Private = ({Item}) => {
  

  return isloged === true? <MenuSistem/> : <Login/>
};

 const handleLogof = () =>{

}
function logout(){       
  localStorage.removeItem("userId");
  localStorage.removeItem("token");
  localStorage.removeItem("typeUser")
  window.location.reload()
  
// navigate("../Pages/Login.js")

}

  return (
    <>
    <BrowserRouter>
    <header id='topo'>
    <Navbar  expand="lg" fixed='top'>
      <Navbar.Brand href="#"><img className='imgLogo'  src={Logo}/></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className='areaDrop' id="basic-navbar-nav">
        <Nav className="mr-auto" >
         
         
          {
            isloged === false &&
            <Nav.Link className='links'> <Link to='Pages/Login' className='links'>Login</Link></Nav.Link>
           
          

          }{
            isloged === true &&
            <>                        
              <Nav.Link className='links'> <Link to='components/menuSistem.js' className='links'>Menú do Sistema</Link></Nav.Link>
              <Nav.Link className='links'> <Button variant='danger' onClick={logout}>Sair</Button></Nav.Link>
            </>
          


          }
          
          <Nav.Link className='links'></Nav.Link>
          <Nav.Link className='links'><Link to='pages/contact/Contact.js' className='links'>Contato</Link></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
       
    </header>
    <div className='submenu'> 

    </div>

    <Routes>
     <Route index element={<Home/>}/>
     <Route path='Pages/myProfile/MyProfile' element={<Private Item={ <MyProfile/>} />} />
     <Route path='Pages/vacancy/Vacancy' element={<Vacancy/>} />    
     <Route path='Pages/CadastroPJ' element={<CadastroPJ/>} />
     <Route path='Pages/CadastroCandidato' element={<CadastroCAndidato/>} />
     <Route path='Pages/Login' element={<Login/>} />
     <Route path='login/LoginEmployer.js' element={<LoginEmployer/>} />
     <Route path='login/LoginPj.js' element={<LoginPj/>} />
     <Route path='Pages/employers/AddEmployer.js' element={<AddEmployer/>} />
     <Route path='Pages/employers/MyProfileEmployer.js' element={ <MyProfileEmployer/>}  />
     <Route path='Pages/employers/PointerRegister.js' element={<PointerRegister/>} /> 
     <Route path='Pages/enterprise/EnterpriseProfile.js' element={<EnterpriseProfile/>} />
     <Route path='Pages/enterprise/Collaborator.js' element={<Collaborator/>} />
     <Route path='Pages/enterprise/PointerHistory.js' element={<PointerHistory/>} />
     <Route path='Pages/employers/RegisterEmployer.js' element={<RegisterEmployer/>} />
     <Route path='PAges/enterprise/holerit.js' element={<Holerit/>}  />
     <Route path='Pages/employers/Vholerit.js' element={<Vholerite/>} />
     <Route path='components/menuSistem.js' element={<Private Item={<MenuSistem/>} />}  />
     <Route path='pages/contact/Contact.js' element={<Contact/>} />
     <Route path='Pages/enterprise/CadDesconts.js' element={<CadDesconts/>} />
     <Route path='Pages/administration/ViewMessage.js' element={<ViewMessage/>} />
     <Route path='Pages/enterprise/DeleteEmployer.js' element={<DeleteEmployer/>} />
     <Route path='Pages/administration/DelCliente.js' element={<DelCliente/>} />
     <Route path='Pages/enterprise/FolhaPagamento.js' element={<FolhaPagamento/>} />
     <Route path='Pages/employers/reembolso.jsx' element={<Reembolso/>} />
   
    </Routes>
    

    
   

   
    
    
    </BrowserRouter> 
    </>   
  );
}

export default App;
