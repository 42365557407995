import {React, useEffect, useState} from 'react';
import '../../App.css';
import { Input } from '../../components/style';
import api from '../../components/api';
import { Table, Button } from 'react-bootstrap';
import Loader from '../../components/loading/Loader';
import CardPointPDF from '../../components/Reports/CardPointPDF';

function PointerHistory(){

    // Profile => id do perfil da empresa solicitante dos dados, a ser enviado via requisição ao backend

    const Profile =   1;
    let ID_enterprise =  localStorage.getItem('enterpriseId');

    const [idemployer, setIdEmployer] = useState([]);
    const [funcionari, setFuncionari] = useState();
    const [pointerColab, setPointerColab] = useState([]);
    const [removeLoader, setRemoveLoader] = useState(false);
  


    useEffect(() => {
        api.get("/api/Auser", 
       { params:{
        enterprise_id: ID_enterprise
        } }).then(({data}) =>{
            setIdEmployer(data)
        })
    }, []);
    
 let totalItens = idemployer.length

 function Selection(){
    return(
        setRemoveLoader(false),
        api.get("/api/viewPointer", {
            params:{
                employer_id: funcionari
            }
        }).then(({data}) =>{
            setPointerColab(data);
            setRemoveLoader(true);
        })
        )      
 }
    return(
    <div className='areaConteudo'> 
    <h3>Histórico de Registros de Pontos de colaboradores</h3>
    <b>** Selecione o Perfil para averiguação</b>
    
    <select value={funcionari} onChange={(e) => setFuncionari(e.target.value)}>
        <option value='0'>Selecione</option>

        {
            idemployer.map(IDemployer => (
                <option key={IDemployer.id} value={IDemployer.id}>{IDemployer.nome}</option>
            ))
        }
    </select> <br/><br/>
    <Button variant='outline-info' onClick={Selection}>Buscar Informações</Button>
    <br/><br/>
    
     <Table  striped bordered hover>
     <thead >
         <th>ID de operação</th>         
         <th>Data</th>
         <th>Entrada Primeiro Periodo</th>
         <th>Saida Primeiro Periodo</th>
         <th>Entrada Segundo Periodo</th>
         <th>Saida Segundo Periodo</th>
     </thead>
     <tbody>
        {removeLoader === false && <td colSpan={2}> <Loader/></td>}
     {pointerColab.length>0 ? pointerColab.map(item =>(
        <tr key={item.id}>
            <td>{item.id}</td>            
            <td>{item.dia}</td>           
            <td>{item.entrada_1_period}</td>
            <td>{item.saida_1_period}</td>
            <td>{item.entrada_2_period}</td>
            <td>{item.saida_2_priod}</td>
        </tr>
     )) :  <td colSpan={8}><h3 style={{textAlign:'center'}}>Sem itens para mostrar</h3></td>}
     
     </tbody>
 </Table>
 <Button variant='success' onClick={(e) => CardPointPDF(pointerColab)}>Imprimir Tabela</Button>
    
    </div>
    )
} export default PointerHistory