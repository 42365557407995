import React, { useState, useEffect } from "react";
import '../../App.css';
import { Button, Form } from "react-bootstrap";
import Loader from './../../components/loading/Loader';
import LoaderPage from "../../components/loading/LoaderPage";
import api from "../../components/api";
import Axios from "axios";

function CadDesconts(){
    const [ colab, setColab] = useState('');
    const [nome, setNome] = useState('')
    const [removeLoader, setRemoveLoader] = useState(false);
   /* const ID_Employer = localStorage.getItem("userId");
    const id_employer = ID_Employer;*/
    const dia = new Date();
    const id_enterprise = localStorage.getItem('enterpriseId');
    const [user, setUser] = useState([]);
    let [id_employer, setIdFuncinario] = useState('');
    const [titulo, setTitulo] = useState('');
    const [valor, setValor] = useState('');
    const [error, setError] = useState('');

    useEffect(() =>{       
        api.get("api/Auser", {
            params:{'enterprise_id': id_enterprise}
        }).then(({data}) =>{
            setUser(data);
            setRemoveLoader(true);
            
        });    
    },[]);
    const handleDescont = async(e)=>{
        e.preventDefault();
      try {
        const response = await Axios.post('http://vps50439.publiccloud.com.br:3000/api/newDescont',
        JSON.stringify({ id_enterprise, id_employer, dia, titulo, valor }),
        {
         headers: {'Content-Type': 'application/json'}
        }    
            
        );   
        alert(response.data)    
        //console.log(response.status);
       
        
     

      } catch (error) {
        if (!error?.response){
            setError("Erro ao accessar o servidor");
        } else if (error.response.status === 401){
            setError("Usuário ou senha inválidos")
        }
      }

    }
    useEffect(()=>{
        api.get("/api/EmployerLocale", {
            params:{
                id: id_employer
            }
        }).then(({data}) =>{
            setColab(data);
            data.filter((resp)=>setNome(resp.nome))
        })
    },[id_employer])
   
    return(<>
    <div className="areaConteudo">
        <h2>Adicionar Descontos</h2>
        {!removeLoader && <Loader/>}
        {removeLoader === true && <>
        <Form.Label>Selecione o Colaborador </Form.Label>
        <select value={id_employer} onChange={(e) => setIdFuncinario(e.target.value)}>
        <option value='0'>Selecione</option>
            {user.map(idUser =>(
                <option key={idUser.id} value={idUser.id}>
                    {idUser.nome}
                </option>
            ))}
        </select> <br/>
     
        <br/>        
        <Form>
            <Form.Group>
                <Form.Label>Nome do Colaborador</Form.Label>
                <Form.Control value={nome} />
                <Form.Label>Descrição</Form.Label>
                <Form.Control value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                <Form.Label>Valor</Form.Label>
                <Form.Control value={valor} onChange={(e) => setValor(e.target.value)} />

            </Form.Group>
        </Form>
        <br/>
        <Button variant="warning" onClick={handleDescont}>Adicionar Desconto</Button>
            </>}
    </div> 
    <br/>
    </>)
}
export default CadDesconts